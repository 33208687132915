function getCookie(name, defval) {
  const nameEq = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEq) === 0) {
      return decodeURIComponent(c.substring(nameEq.length, c.length));
    }
  }
  return typeof defval === 'undefined' ? '' : defval;
}

function setCookie(name, value, option) {
  let str = `${name}=${encodeURIComponent(value)}`;

  if (option) {
    if (option.expireHours) {
      const d = new Date();
      d.setTime(d.getTime() + option.expireHours * 3600 * 1000);
      str += `; expires=${d.toUTCString()}`;
    } else if (option.expireMinutes) {
      const d = new Date();
      d.setTime(d.getTime() + option.expireMinutes * 60 * 1000);
      str += `; expires=${d.toUTCString()}`;
    }
    if (option.path) {
      str += `; path=${option.path}`;
    } else {
      str += '; path=/';
    }
    if (option.domain) {
      str += `; domain=${option.domain}`;
    }
    if (option.secure) {
      str += '; true';
    }
  }
  document.cookie = str;
}

function delCookie(name) {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name, null);
  if (cval !== '') {
    document.cookie = `${name}=${cval};expires=${exp.toGMTString()}`;
  }
}

export { getCookie, setCookie, delCookie };
