import axios from 'axios'
import { Toast  } from 'vant'
import qs from 'qs'
import { getCookie } from '@/utils/cookie'
import { getRequest } from "@/utils/common"

console.log(getRequest(location.href))

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'lang': getRequest(location.href)['lang'] || navigator.language || navigator.browserLanguage || '',
    'pcpopclub': getCookie('token') ? getCookie('token') : 'fault'
  },
  withCredentials: true, // 跨域
  crossDomain: true,
  xhrFields: {
    withCredentials: true
  },
  transformRequest: [function(data) {
    if (data && data.operate) {
      return qs.stringify(data)
    }
    return JSON.stringify(data)
  }],
  timeout: 20000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers.lang = getRequest(location.href)['lang'] || navigator.language || navigator.browserLanguage || ''
    config.headers.pcpopclub = getCookie('token') ? getCookie('token') : 'fault'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    let code = res.code || res.returncode
    if (Number(code) !== 200) {
      if (res.message) {
        Toast(res.message)
      }
      return Promise.reject(res)
    } else {
      return res.data || res.result
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '登录失效，请重新登录'; window.location.href = location.origin + '/#/login'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
      Toast(error.message)
    }
    // Toast("加载失败，请稍后重试")
    return Promise.reject(error)
  }
)

export default service
