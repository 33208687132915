const getRequest = function (url) {
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var strs = url.split('?')[1].split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export { getRequest }
